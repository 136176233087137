import React, { memo, useCallback, useState } from 'react';
import { degreesToRadians } from './utils/math';

import './Start.css';
import { isMobileDevice } from './utils/dom';

const Start = ({ handleStart, started, handleXChange, handleYChange, setStream }) => {
    const [startInProgress, setStartInProgress] = useState(false);

    const handleDeviceOrientationChange = useCallback(
        (e) => {
            handleXChange(-degreesToRadians(e.beta) + (3 * Math.PI) / 8);
            handleYChange(-degreesToRadians(e.alpha));
        },
        [handleXChange, handleYChange]
    );

    const handleButtonClick = useCallback(async () => {
        if (startInProgress || started) return;

        if (!isMobileDevice) {
            handleStart(false);
        } else {
            setStartInProgress(true);
            if (!DeviceMotionEvent.requestPermission) {
                window.addEventListener('deviceorientation', handleDeviceOrientationChange);
                handleStart(true);
            } else {
                try {
                    const permission = await DeviceMotionEvent.requestPermission();
                    if (permission === 'granted') {
                        window.addEventListener('deviceorientation', handleDeviceOrientationChange);
                    }

                    handleStart(true);
                } catch (e) {
                    console.log(e);
                    handleStart(false);
                }
            }

            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: 'environment',
                    },
                });
                setStream(stream);
            } catch (e) {
                console.log(e);
            }

            setStartInProgress(false);
        }
    }, [handleStart, started, handleDeviceOrientationChange, startInProgress, setStream]);

    return (
        <div className="Start">
            <button className="Start__button" onClick={handleButtonClick}>
                Start
            </button>
        </div>
    );
};

export default memo(Start);
