import React, { useCallback, useEffect, useRef, useState } from 'react';

import Ar from './Ar';
import Start from './Start';
import './App.css';

const App = () => {
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [started, setStarted] = useState(false);
    const [stream, setStream] = useState(null);
    const [gyroStarted, setGyroStarted] = useState(false);

    const videoRef = useRef(null);

    const handleStart = useCallback((success) => {
        setGyroStarted(success);
        setStarted(true);
    }, []);

    useEffect(() => {
        if (!stream || !videoRef.current || !gyroStarted) return;

        videoRef.current.srcObject = stream;
        videoRef.current.play();
    }, [stream, gyroStarted]);

    return (
        <div className="App">
            {started ? (
                <>
                    <Ar xRotation={x} yRotation={y} />
                    {gyroStarted && (
                        <div className="App__video-container">
                            <video className="App__video" ref={videoRef} playsInLine="true" />
                        </div>
                    )}
                </>
            ) : (
                <Start
                    handleStart={handleStart}
                    started={started}
                    handleXChange={setX}
                    handleYChange={setY}
                    setStream={setStream}
                />
            )}
        </div>
    );
};

export default App;
