import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import './Ar.css';

const countLevels = (k) => Math.ceil(Math.pow(k / 8, 1 / 3));

const calculateLevelWidth = (level) => level;

const range = (r) =>
    Array(r)
        .fill(0)
        .map((_, i) => i);

const STACK_AMOUNT = 10000;

const Ar = ({ xRotation = 0, yRotation = 0 }) => {
    const [amount, setAmount] = useState(1000000);

    const stacks = useMemo(() => Math.ceil(amount / STACK_AMOUNT), [amount]);
    const levels = useMemo(() => countLevels(stacks), [stacks]);
    const maxLevelWidth = useMemo(() => calculateLevelWidth(levels), [levels]);

    const rendererRef = useRef(null);
    const sceneRef = useRef(null);

    const [money, setMoney] = useState();
    const handleChange = useCallback((e) => {
        setAmount(e.target.value);
    }, []);

    useEffect(() => {
        if (!sceneRef.current) return;
        sceneRef.current.remove.apply(sceneRef.current, sceneRef.current.children);
    }, [stacks]);

    useEffect(() => {
        const fov = 80;
        const aspect = window.innerWidth / window.innerHeight; // the canvas default
        const near = 0.1;
        const far = 1000;
        const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
        camera.position.set(0, 600, 1000);

        const renderer = new THREE.WebGLRenderer({ logarithmicDepthBuffer: true, alpha: true });

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.maxDistance = 500;
        controls.minDistance = 500;
        controls.target.set(0, 0, 0);
        controls.update();

        sceneRef.current = new THREE.Scene();

        const loader = new OBJLoader();

        {
            const skyColor = 0xb1e1ff; // light blue
            const groundColor = 0xb97a20; // brownish orange
            const intensity = 0.7;
            const light = new THREE.HemisphereLight(skyColor, groundColor, intensity);
            sceneRef.current.add(light);
        }

        {
            const color = 0xffffff;
            const intensity = 0.7;
            const light = new THREE.DirectionalLight(color, intensity);
            light.position.set(0, 10, 0);
            light.target.position.set(-5, 0, 0);
            sceneRef.current.add(light);
            sceneRef.current.add(light.target);
        }

        loader.load(
            '/money.obj',
            (result) => {
                const texture = new THREE.TextureLoader().load('/stacks_diffuse_no_ao.jpg');

                result.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        child.material = new THREE.MeshBasicMaterial({ map: texture });
                    }
                });

                setMoney(result);
            },
            (xhr) => {
                console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
            },
            (error) => {
                console.log(`Error: ${error}`);
            }
        );

        renderer.setSize(window.innerWidth, window.innerHeight);

        rendererRef.current.append(renderer.domElement);

        const animate = () => {
            requestAnimationFrame(animate);
            renderer.render(sceneRef.current, camera);
        };
        animate();
    }, []);

    useEffect(() => {
        if (!money || !sceneRef.current || !stacks) return;

        money.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.scale.set(1 / maxLevelWidth, 1 / maxLevelWidth, 1 / maxLevelWidth);
            }
        });

        const box = new THREE.Box3().setFromObject(money);
        const { x, y, z } = box.getSize();

        range(levels * 2).forEach((level) => {
            range(levels).forEach((i) => {
                range(levels * 2).forEach((j) => {
                    if (level * Math.pow(levels, 2) * 2 + i * Math.pow(levels, 2) + j >= stacks) return;

                    const newStack = money.clone();

                    const stackX = x * (i - levels / 2 + 0.5);
                    const stackY = y * level;
                    const stackZ = z * (j - levels / 2 + 0.5);

                    newStack.renderOrder = -(i * levels + j);

                    newStack.position.set(stackX, stackY, stackZ);
                    sceneRef.current.add(newStack);
                });
            });
        });
    }, [maxLevelWidth, money, stacks, levels]);

    useEffect(() => {
        sceneRef.current.rotation.x = xRotation;
        sceneRef.current.rotation.y = yRotation;
    }, [xRotation, yRotation]);

    return (
        <>
            <input type="number" onChange={handleChange} value={amount} className="Ar__input" />
            <div className="Ar" ref={rendererRef} />
        </>
    );
};

export default Ar;
